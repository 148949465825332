@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    html {
        font-family: 'Open Sans', sans-serif;
    }

    ::-webkit-scrollbar {
        width: 0.5rem;
        display: none;
    }

    ::-webkit-scrollbar-track {
        background-color: black;
    }

    ::-webkit-scrollbar-thumb {
        background-color: gray;
        border-radius: 1rem;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #ABDF12;
    }

    ::selection {
        color: black;
        background: #ABDF12;
    }

    .active {
        color: #ABDF12;
        scale: 150%;
    }
}